$breakpoint-lg: 992px;
$tooltip-background-color: var(--color-black);
$tooltip-text-color: var(--color-alpha);

:root {
  --tooltip-font-size: 11px;
  --tooltip-animation-direction: 0px;
}

button[aria-label][data-tooltip-position] {
  overflow: visible;
}

[aria-label][data-tooltip-position] {
  position: relative;
  cursor: pointer;
}

[aria-label][data-tooltip-position]:after {
  position: absolute;
  z-index: 10;
  font-size: var(--tooltip-font-size);
  line-height: 127%;
  color: $tooltip-text-color;
  text-indent: 0;
  text-shadow: none;
  white-space: nowrap;
  pointer-events: none;
  content: attr(aria-label);
  background: $tooltip-background-color;
  opacity: 0;
  transition: all 0.12s ease-out 0.12s;
}

[aria-label][data-tooltip-position]:before {
  position: absolute;
  z-index: 10;
  width: 0;
  height: 0;
  pointer-events: none;
  content: "";
  border: 5px solid transparent;
  border-top-color: $tooltip-background-color;
  opacity: 0;
  transition: all 0.12s ease-out 0.12s;
}

[aria-label][data-tooltip-position]:hover:before,
[aria-label][data-tooltip-position]:hover:after,
[aria-label][data-tooltip-position][data-tooltip-visible]:before,
[aria-label][data-tooltip-position][data-tooltip-visible]:after,
[aria-label][data-tooltip-position]:not([data-tooltip-nofocus]):focus:before,
[aria-label][data-tooltip-position]:not([data-tooltip-nofocus]):focus:after {
  pointer-events: none;
  opacity: 1;
}

[aria-label][data-tooltip-position][data-tooltip-break]:after {
  white-space: pre;
}

[aria-label][data-tooltip-position][data-tooltip-break][data-tooltip-length]:after {
  word-break: break-word;
  white-space: pre-line;
}

[aria-label][data-tooltip-position][data-tooltip-blunt]:before,
[aria-label][data-tooltip-position][data-tooltip-blunt]:after {
  transition: none;
}

[aria-label][data-tooltip-position][data-tooltip-position="up"]:hover:after,
[aria-label][data-tooltip-position][data-tooltip-position="up"][data-tooltip-visible]:after,
[aria-label][data-tooltip-position][data-tooltip-position="down"]:hover:after,
[aria-label][data-tooltip-position][data-tooltip-position="down"][data-tooltip-visible]:after {
  transform: translate(-50%, 0);
}

[aria-label][data-tooltip-position][data-tooltip-position="up"]:hover:before,
[aria-label][data-tooltip-position][data-tooltip-position="up"][data-tooltip-visible]:before,
[aria-label][data-tooltip-position][data-tooltip-position="down"]:hover:before,
[aria-label][data-tooltip-position][data-tooltip-position="down"][data-tooltip-visible]:before {
  transform: translate(-50%, 0);
}

[aria-label][data-tooltip-position][data-tooltip-position*="-left"]:after {
  left: 0;
}

[aria-label][data-tooltip-position][data-tooltip-position*="-left"]:before {
  left: 5px;
}

[aria-label][data-tooltip-position][data-tooltip-position*="-right"]:after {
  right: 0;
}

[aria-label][data-tooltip-position][data-tooltip-position*="-right"]:before {
  right: 5px;
}

[aria-label][data-tooltip-position][data-tooltip-position*="-left"]:hover:after,
[aria-label][data-tooltip-position][data-tooltip-position*="-left"][data-tooltip-visible]:after,
[aria-label][data-tooltip-position][data-tooltip-position*="-right"]:hover:after,
[aria-label][data-tooltip-position][data-tooltip-position*="-right"][data-tooltip-visible]:after {
  transform: translate(0, 0);
}

[aria-label][data-tooltip-position][data-tooltip-position*="-left"]:hover:before,
[aria-label][data-tooltip-position][data-tooltip-position*="-left"][data-tooltip-visible]:before,
[aria-label][data-tooltip-position][data-tooltip-position*="-right"]:hover:before,
[aria-label][data-tooltip-position][data-tooltip-position*="-right"][data-tooltip-visible]:before {
  transform: translate(0, 0);
}

[aria-label][data-tooltip-position][data-tooltip-position^="up"]:before,
[aria-label][data-tooltip-position][data-tooltip-position^="up"]:after {
  bottom: 100%;
  transform: translate(0, var(--tooltip-animation-direction));
  transform-origin: top;
}

[aria-label][data-tooltip-position][data-tooltip-position^="up"]:after {
  margin-bottom: 10px;
}

[aria-label][data-tooltip-position][data-tooltip-position="up"]:before,
[aria-label][data-tooltip-position][data-tooltip-position="up"]:after {
  left: 50%;
  transform: translate(-50%, var(--tooltip-animation-direction));
}

[aria-label][data-tooltip-position][data-tooltip-position^="down"]:before,
[aria-label][data-tooltip-position][data-tooltip-position^="down"]:after {
  top: 100%;
  transform: translate(0, calc(var(--tooltip-animation-direction) * -1));
}

[aria-label][data-tooltip-position][data-tooltip-position^="down"]:after {
  margin-top: 10px;
}

[aria-label][data-tooltip-position][data-tooltip-position^="down"]:before {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-bottom-color: var(--tooltip-background-color);
}

[aria-label][data-tooltip-position][data-tooltip-position="down"]:after,
[aria-label][data-tooltip-position][data-tooltip-position="down"]:before {
  left: 50%;
  transform: translate(-50%, calc(var(--tooltip-animation-direction) * -1));
}

[aria-label][data-tooltip-position][data-tooltip-position="left"]:hover:after,
[aria-label][data-tooltip-position][data-tooltip-position="left"][data-tooltip-visible]:after,
[aria-label][data-tooltip-position][data-tooltip-position="right"]:hover:after,
[aria-label][data-tooltip-position][data-tooltip-position="right"][data-tooltip-visible]:after {
  transform: translate(0, -50%);
}

[aria-label][data-tooltip-position][data-tooltip-position="left"]:hover:before,
[aria-label][data-tooltip-position][data-tooltip-position="left"][data-tooltip-visible]:before,
[aria-label][data-tooltip-position][data-tooltip-position="right"]:hover:before,
[aria-label][data-tooltip-position][data-tooltip-position="right"][data-tooltip-visible]:before {
  transform: translate(0, -50%);
}

[aria-label][data-tooltip-position][data-tooltip-position="left"]:after,
[aria-label][data-tooltip-position][data-tooltip-position="left"]:before {
  top: 50%;
  right: 100%;
  transform: translate(var(--tooltip-animation-direction), -50%);
}

[aria-label][data-tooltip-position][data-tooltip-position="left"]:after {
  margin-right: 10px;
}

[aria-label][data-tooltip-position][data-tooltip-position="left"]:before {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-left-color: $tooltip-background-color;
}

[aria-label][data-tooltip-position][data-tooltip-position="right"]:after,
[aria-label][data-tooltip-position][data-tooltip-position="right"]:before {
  top: 50%;
  left: 100%;
  transform: translate(calc(var(--tooltip-animation-direction) * -1), -50%);
}

[aria-label][data-tooltip-position][data-tooltip-position="right"]:after {
  margin-left: 10px;
}

[aria-label][data-tooltip-position][data-tooltip-position="right"]:before {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-right-color: $tooltip-background-color;
}

[aria-label][data-tooltip-position][data-tooltip-length]:after {
  white-space: normal;
}

@media screen and (max-width: $breakpoint-lg) {
  .is-onboarding-mobile-fixed div[data-popper-placement="bottom"] {
    transform: none !important;
    transition: transform 0.2s linear;
  }
}
