@import "reset";
@import "fonts";
@import "colors";
@import "tooltip-core";
@import "variables";

html {
  text-size-adjust: 100%;
}

:root {
  --photo-spacing-small: 4px;
  --photo-spacing-medium: 16px;
  --photo-spacing-large: 32px;

  --photo-spacing-container-small-mobile: -9px;
  --photo-spacing-container-medium-mobile: -4px;
  --photo-spacing-container-large-mobile: 0;

  // override by js
  --photo-spacing: var(--photo-spacing-medium);
  --sizer-masonry-grid-gutter: var(--photo-spacing);
  --photo-spacing-container-mobile: var(--photo-spacing-container-medium-mobile);
}

body {
  overflow-x: hidden;
  font-family: var(--font-family-museo_sans_cyrl);
  font-style: normal;
  font-weight: 300;
  background: var(--color-white);
  min-height: 100svh;
}

.image-context-menu-disabler {
  img {
    pointer-events: none !important;
    user-select: none !important;
    -webkit-touch-callout: none !important;
    -webkit-user-drag: none !important;
  }
}

.is-hidden-photos-scene {
  h1 {
    color: var(--color-charlie);
  }

  img,
  video {
    opacity: 0.6;
  }

  .image-hidden {
    background-color: var(--color-india);
  }
}

/*
У EnvyChat нет никакого апи,
по этому приходится решать вопросик так,
эти классы навешиваются в комопненте:
features/envybox/components/envybox-chat
 */

body .ws-chat {
  display: none;
}

body.envy-show .ws-chat {
  display: block;
}

/*
  Не работает disableFlip prop-са, пока временное решение
*/

#react-floater-portal:not(.g-tooltip-disable-flip) > * {
  inset: 0 auto auto 0 !important;
  opacity: 1;
}

#react-floater-portal:not(.g-tooltip-disable-flip) > [data-popper-reference-hidden] {
  visibility: hidden !important;
  opacity: 0;
}

img[data-protect="true"] {
  user-select: none;
  -webkit-touch-callout: none;
}

body.lock-scroll {
  height: fill-available;
  overflow: hidden;
  touch-action: unset;
  position: fixed;
  left: 0;
  width: 100%;
  padding-right: var(--scrollbar-width);
}

// YooMoney
.checkout-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000080;
  z-index: 1000;
}

.checkout-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 25px;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: start;
  overflow-y: auto;
  z-index: 2147483647;
  will-change: transform;
}

.checkout-modal__close {
  position: absolute;
  right: 20px;
  top: -10px;
  width: 28px;
  height: 28px;
  padding: 0;
  cursor: pointer;
  border: 0;
  background: transparent;
  box-shadow: none;
  outline: none;

  &:before {
    content: "\2715";
    position: absolute;
    font-size: 25px;
    font-weight: bold;
    color: #828282;
  }
}

.show-mobile {
  display: none;

  @media screen and (max-width: $breakpoint-md) {
    display: block;
  }
}

.show-desktop {
  @media screen and (max-width: $breakpoint-md) {
    display: none;
  }
}

.clearfix {
  zoom: 1;
}
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
}
