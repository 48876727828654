@import "styles/variables.scss";

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  width: 100%;
  min-height: 100vh;
  margin-right: auto;
  margin-left: auto;
  background: #f7f7f7;
}

.wrapper {
  text-align: center;
}

.title {
  margin-top: 45px;
  margin-bottom: 25px;
  font-size: 24px;
  line-height: 1.33;
  font-weight: 300;

  @media only screen and (max-width: $breakpoint-sm) {
    font-size: 22px;
  }

  @media only screen and (max-width: $breakpoint-sm) {
    font-size: 18px;
  }
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
}
