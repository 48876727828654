@font-face {
  font-family: "Museo Sans Cyrl";
  src: url("/fonts/MuseoSansCyrl-100.woff2") format("woff2");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Museo Sans Cyrl";
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/MuseoSansCyrl-300.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Museo Sans Cyrl";
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/MuseoSansCyrl-500.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Museo Sans Cyrl";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/MuseoSansCyrl-700.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Museo Sans Cyrl";
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/MuseoSansCyrl-900.woff2") format("woff2");
  font-display: swap;
}

:root {
  --font-family-museo_sans_cyrl: "Museo Sans Cyrl", sans-serif;
  --font-family-cormorant_garamond: "Cormorant Garamond", serif;
  --font-family-forum: "Forum", serif;
  --font-family-jost: "Jost", sans-serif;
  --font-family-roboto_condensed: "Roboto Condensed", sans-serif;
  --font-family-spectral: "Spectral", serif;
}
