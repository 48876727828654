body.theme-light {
  --color-white: #ffffff;
  --color-white-text: #ffffff;
  --color-black: #000000;
  --color-eerie-black: #181818;
  --color-nickel: #929292;
  --color-smoke-screen: #afafaf;
  --color-cerebral-grey: #cccccc;
  --color-sunny-pavement: #d8d8d8;
  --color-silver-lake: #dedede;
  --color-cold-morning: #e5e5e5;
  --color-super-silver: #eeeeee;
  --color-lighthouse: #f4f4f4;
  --color-vineyard-autumn: #f64953;
  --color-lynx-white: #f7f7f7;
  --color-ghost-white: #f9f9f9;
  --color-dr-white: #fafafa;
  --color-white-fon: #ffffff;
  --color-white-btn-bg: #ffffff;
  --common-color-smoke-screen: #afafaf;
  --common-color-nickel: #929292;
  --common-color-white: #ffffff;
  --common-color-black: #000000;
  --common-super-silver: #eeeeee;
  --spec-color-white: #ffffff;
  --color-alpha: var(--color-white);
  --color-bravo: var(--color-black);
  --color-charlie: var(--color-nickel);
  --color-delta: var(--color-smoke-screen);
  --color-echo: var(--color-cerebral-grey);
  --color-foxtrot: var(--color-sunny-pavement);
  --color-golf: var(--color-silver-lake);
  --color-hotel: var(--color-cold-morning);
  --color-india: var(--color-super-silver);
  --color-juliet: var(--color-lighthouse);
  --color-kilo: var(--color-vineyard-autumn);
  --color-lima: var(--color-lynx-white);
  --color-mike: var(--color-dr-white);
  --color-rasin: var(--color-india);
}

body.theme-dark {
  --color-white: #141414;
  --color-white-text: #000000;
  --color-black: #ffffff;
  --color-eerie-black: #181818;
  --color-nickel: #929292;
  --color-smoke-screen: #545454;
  --color-cerebral-grey: #545454;
  --color-sunny-pavement: #d8d8d8;
  --color-silver-lake: #343434;
  --color-cold-morning: #343434;
  --color-super-silver: #424242;
  --color-lighthouse: #242424;
  --color-vineyard-autumn: #f64953;
  --color-lynx-white: #343434;
  --color-ghost-white: #141414;
  --color-dr-white: #141414;
  --color-white-fon: #242424;
  --color-white-btn-bg: #424242;
  --common-color-smoke-screen: #afafaf;
  --common-color-nickel: #929292;
  --common-color-white: #ffffff;
  --common-color-black: #000000;
  --common-super-silver: #eeeeee;
  --spec-color-white: #181818;
  --color-alpha: var(--color-white);
  --color-bravo: var(--color-black);
  --color-charlie: var(--color-nickel);
  --color-delta: var(--color-smoke-screen);
  --color-echo: var(--color-cerebral-grey);
  --color-foxtrot: var(--color-sunny-pavement);
  --color-golf: var(--color-silver-lake);
  --color-hotel: var(--color-cold-morning);
  --color-india: var(--color-super-silver);
  --color-juliet: var(--color-lighthouse);
  --color-kilo: var(--color-vineyard-autumn);
  --color-lima: var(--color-lynx-white);
  --color-mike: var(--color-dr-white);
  --color-rasin: #242424;
}
